import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Showlist from "./components/Showlist";

const App = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash.length <= 1) {
      navigate("#calin");
    }
  }, []);

  return (
    <div className="w-full min-h-screen bg-theme-dark selection:bg-sky-500/[.5]">
      <div className="w-full bg-theme-dark">
        <Header />
      </div>
      <div className="w-full bg-theme-dark">
        <Showlist artist={hash.slice(1)} />
      </div>
      <div className="text-xs text-center opacity-50 py-[4.5rem] text-neutral-200">
        {new Date().getFullYear()} &copy; MIKE ROFT, s.r.o.
      </div>
    </div>
  );
};

export default App;
