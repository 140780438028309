import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";
import { useLocation } from "react-router-dom";
import { artists } from "../artists";
import MRFTLogo from "../assets/mrft-logo.svg";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const { hash } = useLocation();

  return (
    <div className="p-4 md:max-w-5xl md:mx-auto sm:p-12">
      <img
        className="mr-auto ml-8 h-[30px] sm:mx-auto mt-8"
        src={MRFTLogo}
        alt="MIKE ROFT"
      />

      <div className="w-full py-12">
        <nav
          className="flex flex-col justify-center mx-auto sm:space-x-4 sm:flex-row"
          aria-label="Tabs"
        >
          {artists.map((artist) => (
            <a
              key={artist.name}
              href={`#${artist.slug}`}
              className={classNames(
                hash === `#${artist.slug}`
                  ? "opacity-100"
                  : "opacity-50 hover:opacity-75",
                "items-center text-neutral-200 px-8 sm:px-3 py-1 sm:py-2 font-bold text-2xl text-left transition-all"
              )}
              aria-current={hash === `#${artist.slug}` ? "page" : undefined}
            >
              <span>{artist.name}</span>
            </a>
          ))}

          <a
            href="https://shop.mikeroft.com/"
            className="flex items-center px-8 py-1 text-2xl font-bold text-left transition-all opacity-75 hover:opacity-100 text-neutral-200 sm:px-3 sm:py-2"
          >
            <span>Shop</span>
            <ArrowRightIcon className="w-5 h-5 ml-1.5" />
          </a>
        </nav>
      </div>
    </div>
  );
}
