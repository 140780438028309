import React from "react";
import { format, parseISO } from "date-fns";
import { cs } from "date-fns/locale";
import { useQuery } from "react-query";
import Loading from "./Loading";
import { Tooltip } from "@mantine/core";
import { InformationCircleIcon, TicketIcon } from "@heroicons/react/solid";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

interface Show {
  date: string;
  city: string;
  soldout: boolean;
  label?: string;
  venue?: string;
  eventlink?: string;
  ticketslink?: string;
}

export default function Showlist({ artist }: { artist: string }) {
  const {
    isLoading,
    error,
    data: shows,
  } = useQuery(["shows", artist], () =>
    fetch(`https://shows-backend.azurewebsites.net/shows/${artist}`).then(
      (res) => res.json()
    )
  );

  if (isLoading || error) {
    return (
      <div className="flex justify-center w-full py-32">
        <Loading />
      </div>
    );
  }

  return (
    <div className="md:max-w-5xl md:mx-auto">
      <div className="flex flex-col min-w-full text-xl divide-y divide-neutral-200/[.25] text-neutral-200">
        {shows.length === 0 ? (
          <div className="flex justify-center w-full">
            <div className="text-xl text-white opacity-50 text-bold">
              Tady zrovna nic není.
            </div>
          </div>
        ) : (
          shows.map((show: Show, index: number) => (
            <div
              className="border-neutral-200/[.25] px-12 border-b grid grid-cols-10 py-7 gap-x-8 gap-y-6 show-row"
              key={index}
            >
              <div className="flex flex-col col-span-10 sm:col-span-2 sm:flex-col-reverse show-row__date">
                <div className="text-xs opacity-75">
                  {format(parseISO(show.date), "eeee", { locale: cs })}
                </div>
                <div className="font-bold">
                  {format(parseISO(show.date), "dd")}
                  <span className="px-1 font-normal opacity-50">|</span>
                  {format(parseISO(show.date), "MM")}
                  <span className="px-1 font-normal opacity-50">|</span>
                  {format(parseISO(show.date), "yyyy")}
                </div>
              </div>
              <div className="col-span-10 sm:px-0 sm:col-span-6">
                <div className="font-bold">
                  {show.label === undefined
                    ? show.city
                    : `${show.city} (${show.label})`}
                </div>
                <div className="text-xs opacity-75">{show.venue}</div>
              </div>
              <div className="flex items-center justify-end col-span-10 space-x-3 text-neutral-300 sm:px-0 sm:col-span-2">
                {show.soldout ? (
                  <div className="flex flex-col items-start justify-center w-full h-full text-xs font-bold leading-4 tracking-wider uppercase sm:items-center sm:ml-3">
                    <span>vyprodáno</span>
                  </div>
                ) : (
                  <>
                    <a
                      className={classNames(
                        show.eventlink === undefined
                          ? "opacity-25 cursor-not-allowed"
                          : "cursor-pointer hover:bg-white/20",
                        "w-1/2 sm:w-[60px] flex justify-center items-center py-1.5 text-xs font-bold leading-4 tracking-wider uppercase border border-neutral-200 transition-colors text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      )}
                      title="Informace"
                      href={show.eventlink || "#"}
                      target="_blank"
                    >
                      <InformationCircleIcon
                        className={classNames(
                          "text-white",
                          "w-5 h-5 mr-2 sm:mr-0"
                        )}
                      />
                      <span className="block sm:hidden">Informace</span>
                    </a>

                    <a
                      className={classNames(
                        show.ticketslink === undefined
                          ? "opacity-25 cursor-not-allowed"
                          : "cursor-pointer hover:bg-white/20",
                        "w-1/2 sm:w-[60px] flex justify-center items-center py-1.5 text-xs font-bold leading-4 tracking-wider uppercase border border-neutral-200 transition-colors text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      )}
                      title="Vstupenky"
                      href={show.ticketslink || "#"}
                      target="_blank"
                    >
                      <svg
                        className="mr-2 sm:mr-0"
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 3V7C1.65685 7 3 8.34315 3 10C3 11.6569 1.65685 13 0 13V17H7V15H9V17H24V13C22.3431 13 21 11.6569 21 10C21 8.34315 22.3431 7 24 7V3H9V5H7V3H0ZM7 11H9V13H7V11ZM7 7H9V9H7V7Z"
                          fill={"white"}
                        />
                      </svg>
                      <span className="block sm:hidden">Vstupenky</span>
                    </a>
                  </>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
